import { Text } from "grommet";
import { useCoin } from "@organisms/coin/provider";

export default function Marketcap({ slug, ...rest }) {
  const { marketcap, address } = useCoin(slug);

  if (!address)
    return (
      <Text size="small" color="dark-4" {...rest}>
        No Contract
      </Text>
    );

  if (marketcap)
    return (
      <Text size="small" {...rest}>
        {marketcap}
      </Text>
    );

  return (
    <Text size="small" color="dark-4" {...rest}>
      -
    </Text>
  );
}
